import { IconButton, IconButtonProps } from "@mui/material";
import { ReactNode } from "react";
import Tooltip from "../Tooltip";

export interface ITableIconButtonProps extends IconButtonProps {
    title?: string;
    icon?: ReactNode | null;
    href?: string;
}

const TableIconButton = ({ icon, children, title = "", href, ...other }: ITableIconButtonProps) => {
    return (
        <Tooltip title={title}>
            <span>
                {href ? (
                    <IconButton href={href} target="_self" rel="noopener noreferrer" sx={{ p: 0.5 }}>
                        {icon ?? children}
                    </IconButton>
                ) : (
                    <IconButton {...other} sx={{ p: 0.5 }}>
                        {icon ?? children}
                    </IconButton>
                )}
            </span>
        </Tooltip>
    );
};

export default TableIconButton;
